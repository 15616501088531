<template>
  <layout :loading="loading">
    <template v-slot:content>
      <div class="flex items-center mt-5">
        <label class="w-16 font-semibold">Week</label>
        <t-select class="w-44" v-model="weekFilter" :options="weeks"></t-select>
      </div>
      <h1 class="text-xl mt-3 mb-5">
        {{ $t("free_day_overview") }}
      </h1>
      <t-table :headers="headers" :data="overview">
        <template slot="row" slot-scope="props">
          <tr>
            <td :class="props.tdClass">
              {{ props.row.from | dateHour }} - {{ props.row.until | dateHour }}
            </td>
            <td :class="props.tdClass">{{ props.row.name }}</td>
            <td :class="props.tdClass">{{ props.row.job }}</td>
            <td :class="props.tdClass">{{ props.row.created_at }}</td>
          </tr>
        </template>
      </t-table>
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout.vue";
import { actions, getters } from "@/constants/state";
import { getWeekOptions } from "@/utils/misc";
import { mapActions, mapGetters } from "vuex";
import moment from "@/utils/moment-utc";

export default {
  components: { Layout },
  data: () => ({
    weekFilter: moment().startOf("week").add(1, "d").format("YYYY-MM-DD"),
    loading: false,
  }),
  methods: {
    ...mapActions({
      getFreeDaysOverview: actions.FREEDAYS_FETCH_OVERVIEW_ACTION,
    }),
  },
  computed: {
    ...mapGetters({
      overview: getters.FREEDAYS_OVERVIEW_GETTER,
      isAdminOrSuperUser: getters.AUTH_IS_ADMIN_OR_SUPERUSER_GETTER,
    }),
    weeks() {
      return getWeekOptions(this.isAdminOrSuperUser);
    },
    headers() {
      return [
        this.$i18n.t("date"),
        this.$i18n.t("name"),
        this.$i18n.t("job"),
        this.$i18n.t("created"),
      ];
    },
  },
  watch: {
    weekFilter: {
      deep: true,
      async handler(oldWeek, newWeek) {
        if (oldWeek === newWeek) {
          return;
        }

        this.$emit("setLoading", true);

        try {
          await this.getFreeDaysOverview({
            from: this.weekFilter,
            until: moment(this.weekFilter).add(6, "d").format("YYYY-MM-DD"),
          });
        } catch (error) {
          console.error(actions.FREEDAYS_FETCH_OVERVIEW_ACTION, error);
        }

        this.$emit("setLoading", false);
      },
    },
  },
  filters: {
    dateHour(value) {
      return moment(value).format("YYYY-MM-DD HH:mm");
    },
  },
};
</script>

<style lang="scss" scoped></style>
